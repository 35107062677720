import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import {
  Button,
  DataTable,
  Icon,
  Overlay,
  Subhead,
  Text,
} from 'hudl-uniform-ui-components';
import overlayPreviewData from '../../../data/previews/overlay.yml';
import pageHeroData from '../../../data/pages/components.yml';
import styles from './styles/overlay.module.scss';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverlayOpen: false,
      currentCase: 'primary',
    };
  }

  handleCaseChange(value) {
    this.setState({
      currentCase: value,
    });
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Overlay"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Overlay" />

        <PageNavigation>
          <PageNavigationLink>Cases</PageNavigationLink>
          <PageNavigationLink>Behavior</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Cases">
          <Paragraph>
            It’s all a matter of how the overlay workflow relates to the main
            interface.
          </Paragraph>

          <ComponentPreview
            name="Overlay"
            layout="row"
            previewData={overlayPreviewData.cases}
            onChange={this.handleCaseChange.bind(this)}>
            <Button
              type="primary"
              text="Trigger Overlay"
              onClick={() => this.setState({ isOverlayOpen: true })}
              ignoreThisComponent
            />
            <Overlay
              isOpen={this.state.isOverlayOpen}
              onClose={() => this.setState({ isOverlayOpen: false })}>
              {this.state.currentCase === 'primary' ? (
                <div className={styles.primaryOverlayWrapper}>
                  <div className={styles.primaryOverlayVideo}>
                    <img
                      src={require('../../../imgs/previews/preview-overlay-video.jpg')}
                    />
                    <div className={styles.primaryOverlayVideoControls}>
                      <Icon type="slowbackward" />
                      <Icon type="rewind" />
                      <Icon type="play" />
                      <Icon type="fastforward" />
                      <Icon type="slowforward" />
                    </div>
                  </div>
                  <div className={styles.primaryOverlayDetails}>
                    <Subhead>Defensive Rebound Percentage</Subhead>
                    <Text
                      level="micro"
                      color="subtle"
                      className="uni-margin--one--btm">
                      3m 52s 29 Clips from 1 Video
                    </Text>
                    <Button size="small" isBlock type="secondary">
                      Send to Highlights
                    </Button>
                    <ul className={styles.primaryOverlayList}>
                      <li>Def Rebound at 0:56</li>
                      <li>Def Rebound at 3:04</li>
                      <li>Def Rebound at 6:49</li>
                      <li>Def Rebound at 7:05</li>
                      <li>Def Rebound at 9:17</li>
                      <li>Def Rebound at 9:44</li>
                      <li>Def Rebound at 12:08</li>
                      <li>Def Rebound at 15:56</li>
                    </ul>
                  </div>
                </div>
              ) : (
                <DataTable
                  columnContentTypes={[
                    'text',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                  ]}
                  columnShowSortArrows={[false, false, false, false, false]}
                  columnHeaders={['', 'G', 'MP', 'FG', 'FGA', 'FG%']}
                  rows={[
                    {
                      id: '1',
                      data: ['Team', '75', '17985', '2944', '6480', '.454'],
                    },
                    {
                      id: '2',
                      data: ['Team/G', '', '243.0', '39.8', '87.6', '.454'],
                    },
                    { id: '3', data: ['Lg Rank', '', '4', '20', '20', '18'] },
                    {
                      id: '4',
                      data: ['Year/Year', '', '0.4%', '2.9%', '-1.4%', '+.019'],
                    },
                    {
                      id: '5',
                      data: ['Opponent', '77', '18705', '3247', '6871', '.473'],
                    },
                    {
                      id: '6',
                      data: ['Opponent/G', '', '242.9', '42.2', '89.2', '.473'],
                    },
                    { id: '7', data: ['Lg Rank', '', '5', '25', '18', '27'] },
                    {
                      id: '8',
                      data: ['Year/Year', '', '0.3%', '2.9%', '2.8%', '+.000'],
                    },
                    {
                      id: '9',
                      data: ['Team', '75', '17985', '2944', '6480', '.454'],
                    },
                    {
                      id: '10',
                      data: ['Team/G', '', '243.0', '39.8', '87.6', '.454'],
                    },
                    { id: '11', data: ['Lg Rank', '', '4', '20', '20', '18'] },
                    {
                      id: '12',
                      data: ['Year/Year', '', '0.4%', '2.9%', '-1.4%', '+.019'],
                    },
                    {
                      id: '13',
                      data: ['Opponent', '77', '18705', '3247', '6871', '.473'],
                    },
                    {
                      id: '14',
                      data: ['Opponent/G', '', '242.9', '42.2', '89.2', '.473'],
                    },
                    { id: '15', data: ['Lg Rank', '', '5', '25', '18', '27'] },
                    {
                      id: '16',
                      data: ['Year/Year', '', '0.3%', '2.9%', '2.8%', '+.000'],
                    },
                  ]}
                />
              )}
            </Overlay>
          </ComponentPreview>
        </Section>

        <Section title="Behavior">
          <Paragraph>
            The overlay has a couple of fun quirks. One is automatic, the other
            is up to you.
          </Paragraph>
          <SectionSubhead>Sizing</SectionSubhead>
          <Paragraph>
            The overlay is designed to fill 80% of the viewport, in both height
            and width. <em>However</em>, at 1024px it responds to fill the
            entire view. (That’s the same point at which our navbar changes, so
            it shouldn’t seem too weird.)
          </Paragraph>
          <SectionSubhead>Dismiss</SectionSubhead>
          <Paragraph>
            A dismissal method isn’t required, but you have options as to what
            would do the trick.
          </Paragraph>
          <List type="unordered">
            <li>Hitting the ESC key</li>
            <li>Clicking the scrim</li>
            <li>
              Clicking the dismiss—but you can’t have this without the rest of
              the header.
            </li>
          </List>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Like the modal, design separate interfaces using the{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/app-architecture/modality/">
              modal view
            </Link>
            . Reserve any interjection on mobile to the{' '}
            <Link href="/components/alert/design/">alert</Link>.
          </Paragraph>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Header</SectionSubhead>
          <Paragraph>
            <strong>The overlay’s header is optional.</strong> If you choose to
            include the header, it comes with a title and dismiss. You can’t
            have one without the others!
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="avoid a header by incorporating a title into the content."
              img="overlay-header-dont"
            />
            <DontDoItem
              type="do"
              text="include a header if the title and/or dismiss would benefit the workflow."
              img="overlay-header-do"
            />
          </DontDo>

          <SectionSubhead>Imported Content</SectionSubhead>
          <Paragraph>
            All imported content should come with its natural{' '}
            <Link href="/components/themes/environment/design">
              environment
            </Link>
            , regardless of the main interface environment. If there is no
            “natural” environment, mirror that of the main interface.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="rely on the environment of the main interface for all imported content."
              img="overlay-imported-content-dont"
            />
            <DontDoItem
              type="do"
              text="inherit the environment relative to the overlay’s content."
              img="overlay-imported-content-do"
            />
          </DontDo>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            If you include a title, make sure it explains what they’re seeing or
            doing in the overlay. Keep it brief with keywords at the front,
            title case. Our{' '}
            <Link href="/words/content-elements/labels-non-label-text#UI%20Labels">
              label guidelines
            </Link>{' '}
            can help.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use the title as a means of onboarding the user to that workflow."
              img="overlay-titles-dont"
            />
            <DontDoItem
              type="do"
              text="clarify what the triggered overlay allows the user to do."
              img="overlay-titles-do"
            />
          </DontDo>
          <SectionSubhead>Buttons</SectionSubhead>
          <Paragraph>
            If your overlay features any buttons, each should very clearly state
            its action. Avoid complete sentences, opt for a strong verb phrase
            instead. Title case always.
          </Paragraph>
          <Paragraph>
            You can read more about button microcopy in our{' '}
            <Link href="/components/buttons/button/design">
              button guidelines
            </Link>
            .
          </Paragraph>
        </Section>
        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
